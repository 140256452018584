import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export const PrivateRoute = ({ children, ...rest }: any) => {
  const token = sessionStorage.getItem('token');
  return (
    <Routes>
      <Route
        {...rest}
        element={
          token ? (
            children
          ) : (
            <Navigate
              to={{
                pathname: '/login',
              }}
            />
          )
        }
      />
    </Routes>
  );
};
